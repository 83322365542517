import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendUrl = process.env.VUE_APP_BACKEND_URL;
const backendUrlv2 = process.env.VUE_APP_BACKEND_URL_V2;

export default class PlansService {
  static async getPlans(page, limit, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `page=${page}&limit=${limit}`;
      if (search) {
        url = `page=${page}&limit=${limit}&search=${search}`;
      }
      const response = await axios.get(`${adminUrl}/plans?${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getPlanByCompanyId(companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/company/plans?id=${companyId}&visible=true`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async getB2CPlanToAssociate(id) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/plans/b2c/plans/availables?countryId=${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async getPlanDetails(planId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/plans/${planId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createPlan(plan) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const response = await axios.post(`${adminUrl}/plans?iso=CL&flow=false`, plan, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      return { status: 'error' };
    }
  }

  static async updatePlan(id, data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/plans/${id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async deletePlan(id) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.delete(`${adminUrl}/plans/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  // Suppliers
  static async getSuppliers() {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/suppliers?page=1&limit=100`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getSuppliersPlans(page, limit) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${backendUrl}/suppliers-plans?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  // Services/benefits
  static async getServicesByPlan(planId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${backendUrl}/services?plan=${planId}&onlyRedeemable=${true}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  // v2
  static async getPlanFeatures(planId) {
    try {
      const response = await axios.get(`${backendUrlv2}/plans/features/${planId}`);
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.plan.features };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  static async addServiceToPlan(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${backendUrl}/services-plans`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateServicePlan(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${backendUrl}/services-plans`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async removeServiceFromPlan(planId, serviceId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.delete(
        `${backendUrl}/services-plans/${planId}/services/${serviceId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async addBenefitToPlan(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${backendUrlv2}/benefit-plan`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async removeBenefitFromPlan(planId, benefitId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.delete(`${adminUrl}/plans/${planId}/benefits/${benefitId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async addFeatureToPlan(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${backendUrlv2}/plans/features`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getPlansByUserId(userId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/plans/availables/${userId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }
}
