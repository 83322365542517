<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Planes</h1>
      <v-dialog
        persistent
        v-model="showDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-md-block"
            rounded
            color="primary"
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <span class="d-none d-md-block">Agregar Plan</span>
          </v-btn>
          <v-btn
            class="d-block d-md-none"
            rounded
            color="primary"
            fab
            depressed
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </template>
        <template>
          <plan-maintainer
            :plan="selectedPlan"
            @update-list="getPlans(this.page, this.limit)"
            @close-dialog="closeDialog"
          ></plan-maintainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="deleteDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <v-card class="pa-6">
            <v-card-title class="text-uppercase">
              <div>Eliminar plan {{ selectedPlan != null ? selectedPlan.name : '' }}</div>
            </v-card-title>
            <hr class="my-3" />
            <v-card-text class="my-0 py-0">
              <p>¿Está seguro de que desea eliminar el plan?</p>
            </v-card-text>
            <hr class="my-3" />
            <v-card-actions class="justify-end">
              <v-btn color="primary" rounded @click="deletePlan(selectedPlan)">
                Sí, eliminar
              </v-btn>
              <v-btn text color="primary" rounded @click="closeDialog">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="plans"
      :loading="loadingData"
      :options.sync="options"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              v-model="search"
              label="Buscar"
              :disabled="loadingData"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchFn"
              @keyup="cleanSearch"
              @keyup.enter="searchFn"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.plan="{ item }">
        <div>
          <p class="font-weight-bold ma-0">{{ item.name }}</p>
        </div>
      </template>
      <template v-slot:item.annualPrice="{ item }">
        <div v-if="item.annualPrice != null">
          <span v-if="item.currency == 'CLP'">{{ item.annualPrice | clpCurrency }}</span>
          <span v-if="item.currency == 'UF'">{{ item.annualPrice | clpCurrency }}</span>
        </div>
      </template>
      <template v-slot:item.monthlyPrice="{ item }">
        <div v-if="item.monthlyPrice != null">
          <span v-if="item.currency == 'CLP'">{{ item.monthlyPrice | clpCurrency }}</span>
          <span v-if="item.currency == 'UF'">{{ item.monthlyPrice | clpCurrency }}</span>
        </div>
      </template>
      <template v-slot:item.animalType="{ item }">
        <v-chip
          v-if="item.animalType === 'cat' || item.animalType === null"
          small
          color="green"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-cat </v-icon> Gato</v-chip
        >
        <v-chip
          v-if="item.animalType === 'dog' || item.animalType === null"
          small
          color="orange"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-dog </v-icon> Perro</v-chip
        >
      </template>
      <template v-slot:item.metrics="{ item }">
        <span>{{ item.metrics.actives }} / {{ item.metrics.total }}</span>
      </template>
      <template v-slot:item.visible="{ item }">
        <v-icon color="green" v-if="item.visible">mdi-check-circle</v-icon>
        <v-icon color="red" v-if="!item.visible">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              depressed
              v-bind="attrs"
              v-on="on"
              color="pink"
              @click="triggerDeleteModal(item)"
            >
              <v-icon small dark>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              color="blue"
              @click="navitageToPlan(item)"
            >
              <v-icon small dark>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Revisar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PlanMaintainer from '../components/PlanMaintainer';
import PlansService from '../services/plans.service';

export default {
  components: {
    PlanMaintainer
  },
  data() {
    return {
      search: '',
      showDialog: false,
      deleteDialog: false,
      selectedPlan: null,
      loadingData: false,
      options: {},
      plans: [],
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Plan',
          sortable: false,
          value: 'plan'
        },
        {
          text: 'Descripción interna',
          sortable: false,
          value: 'internalDescription'
        },
        {
          text: 'Valor anual',
          sortable: false,
          value: 'annualPrice'
        },
        {
          text: 'Valor mensual',
          sortable: false,
          value: 'monthlyPrice'
        },
        {
          text: 'Visible',
          sortable: false,
          value: 'visible'
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getPlans(this.page, this.limit, this.search);
      },
      deep: true
    }
  },
  methods: {
    async searchFn() {
      this.sort = [];
      this.getPlans(1, 10, this.search);
    },
    async cleanSearch() {
      if (this.search.length === 0) {
        this.sort = [];
        this.getPlans(1, 10, '');
      }
    },
    async getPlans(page, limit) {
      this.loadingData = true;
      const res = await PlansService.getPlans(page, limit, this.search);
      if (res.status === 'ok') {
        this.plans = res.data.plans;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    openDialog(plan) {
      this.selectedPlan = plan;
      this.showDialog = true;
    },
    closeDialog() {
      this.getPlans(this.page, this.limit);
      this.showDialog = false;
      this.deleteDialog = false;
      this.selectedPlan = null;
    },
    async deletePlan(plan) {
      this.loadingData = true;
      const res = await PlansService.deletePlan(plan.id);
      if (res.status === 'ok') {
        this.closeDialog();
      }
      this.loadingData = false;
    },
    triggerDeleteModal(plan) {
      this.deleteDialog = true;
      this.selectedPlan = plan;
    },
    navitageToPlan(plan) {
      this.$router.push({ path: `/business-relationships/plans/${plan.id}` });
    }
  }
};
</script>
