import Vue from 'vue';
import VueRouter from 'vue-router';
import Donations from '../views/Donations.vue';
import Login from '../views/Login.vue';
import Memberships from '../views/Memberships.vue';
import Payments from '../views/Payments.vue';
import Pets from '../views/Pets.vue';
import PlanDetails from '../views/PlanDetails.vue';
import Plans from '../views/Plans.vue';
import Users from '../views/Users.vue';
import Companies from '../views/Companies.vue';
import ServicesRecords from '../views/ServicesRecords.vue';
import RegisterRefund from '../views/RegisterRefund.vue';
import CreateUser from '../views/CreateUser.vue';
import MRRResume from '../views/MrrResume.vue';
import AddPet from '../views/AddPet.vue';
import RefundView from '../views/RefundView.vue';
import Data from '../views/Data.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Usuarios',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/',
        redirect: () => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: '/memberships' };
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/users/create-user',
        name: 'Crear Usuario',
        component: CreateUser
      },
      {
        path: '/data',
        name: 'Users',
        component: Data
      },
      {
        path: '/siniestralidad',
        name: 'Siniestralidad',
        component: Users
      },
      {
        path: '/services',
        name: 'Uso de servicios',
        component: Users
      },
      {
        path: '/memberships',
        name: 'Companies',
        component: Memberships
      },
      {
        path: '/memberships/:userId',
        name: 'Memberships by User',
        component: Memberships
      },
      {
        path: '/pets',
        name: 'Pets',
        component: Pets
      },
      {
        path: '/pets/:userId',
        name: 'Pets by User',
        component: Pets
      },
      {
        path: '/memberships/:userId/add',
        name: 'AddPet',
        component: AddPet,
        props: true
      },
      {
        path: '/dxp',
        name: 'Solicitud DxP',
        component: Pets
      },
      {
        path: '/sponsorships',
        name: 'Apadrinamiento',
        component: Pets
      },
      {
        path: '/business-relationships/plans',
        name: 'Plans',
        component: Plans
      },
      {
        path: '/business-relationships/plans/:planId',
        name: 'PlanDetails',
        component: PlanDetails,
        props: true
      },
      {
        path: '/business-relationships/companies',
        name: 'Empresas',
        component: Companies
      },
      {
        path: '/business-relationships/companies/:companyId',
        name: 'Empresas',
        component: Companies
      },
      {
        path: '/business-relationships/alliances',
        name: 'Empresas',
        component: Companies
      },
      {
        path: '/finances/mrr',
        name: 'MRR Resumen',
        component: MRRResume
      },
      {
        path: '/finances/payments',
        name: 'Pagos recibidos',
        component: Payments
      },
      {
        path: '/finances/donations',
        name: 'Donaciones',
        component: Donations
      },
      {
        path: '/registers/register-refunds',
        name: 'RegisterRefund',
        component: RegisterRefund
      },
      {
        path: '/registers/servicesrecords',
        name: 'Registro Servicios',
        component: ServicesRecords
      },
      {
        path: '/refunds/new',
        name: 'Refunds2',
        component: RefundView
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
