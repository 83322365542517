<template>
  <v-card class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>{{ this.company === null ? 'Crear ' : 'Detalle' }} Empresa</div>
        <v-switch
          v-if="company !== null"
          v-model="editing"
          label="Editar"
          dense
          hide-details="auto"
        ></v-switch>
      </v-row>
    </v-card-title>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0">
      <v-form ref="newCompanyForm" v-model="formValid">
        <v-container class="pa-0">
          <v-col cols="12">
            <v-text-field
              label="Nombre"
              v-model="newCompany.name"
              :rules="nameRules"
              :disabled="disabled()"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Descripción interna"
              v-model="newCompany.description"
              :rules="nameRules"
              :disabled="disabled()"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="newCompany.type"
              label="Tipo de empresa"
              :items="companyTypes"
              @change="changeCompanyType"
              :disabled="disabled()"
              item-value="value"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-arrow-down"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="newCompany.type != 'dxp' && newCompany.type != 'broker'">
            <v-text-field
              label="Límite Membresías gratuitas"
              v-model="newCompany.membershipLimit"
              :rules="limitRules"
              :disabled="disabled()"
              required
              outlined
              dense
              type="number"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Objetivo Usuarios activos"
              v-model="newCompany.userObj"
              :rules="limitRules"
              :disabled="disabled()"
              required
              outlined
              dense
              type="number"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-row class="ma-0 pa-0">
            <v-col cols="6">
              <v-text-field
                label="Obj Membresías activas"
                v-model="newCompany.membershipObj"
                :rules="membershipObjRules"
                :disabled="disabled()"
                required
                outlined
                dense
                type="number"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="newCompany.metric"
                label="Métrica"
                :items="metrics"
                @change="changeMetric"
                :disabled="disabled()"
                item-value="value"
                placeholder=""
                dense
                hide-details
                outlined
                append-icon="mdi-arrow-down"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn
        v-if="company === null || editing"
        :disabled="!formValid"
        color="primary"
        rounded
        @click="validate"
      >
        Guardar
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CompaniesService from '../services/companie.service';

export default {
  name: 'CompanyMaintainer',
  props: ['company'],
  data() {
    return {
      companyTypes: [
        { value: 'assistance', text: 'Asistencia' },
        { value: 'eb', text: 'Employee Benefit' },
        { value: 'dxp', text: 'Dcto x Planilla' },
        { value: 'broker', text: 'Broker' }
      ],
      metrics: [
        { value: 'percentage', text: 'Porcentaje' },
        { value: 'numeric', text: 'Cantidad' }
      ],
      editing: false,
      loading: false,
      activePicker: null,
      newRut: '',
      newCompany: {
        name: '',
        description: '',
        type: '',
        membershipLimit: 0,
        userObj: 0,
        membershipObj: 0,
        metric: ''
      },
      formValid: false,
      nameRules: [v => !!v || 'El nombre es requerido'],
      emailRules: [
        v => !!v || 'Un correo electronico es requerido',
        v => /.+@.+/.test(v) || 'Debe ser un correo electronico valido'
      ],
      limitRules: [v => /^[0-9]\d*$/.test(v) || 'Solo números mayores a 0'],
      membershipObjRules: [
        v => /^[0-9]\d*$/.test(v) || 'Solo números', // Ensure the input is numeric
        v =>
          this.newCompany.metric !== 'percentage' ||
          (v >= 0 && v <= 100) ||
          'Debe estar entre 0 y 100' // Validate range only if metric is 'percentage'
      ]
    };
  },
  mounted() {
    this.newCompany = {};
    this.$refs.newCompanyForm.resetValidation();
    if (this.company !== null) {
      this.parseCompany();
    }
  },
  watch: {
    company(val) {
      this.newCompany = {};
      this.$refs.newCompanyForm.resetValidation();
      if (val !== null) {
        this.parseCompany();
      }
    }
  },
  methods: {
    disabled() {
      if (this.company === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
    },
    parseCompany() {
      this.newCompany = {
        name: this.company.name,
        description: this.company.description,
        type: this.company.type,
        membershipLimit: parseInt(this.company.membershipLimit),
        userObj: parseInt(this.company.userObj),
        membershipObj: parseInt(this.company.membershipObj),
        metric: this.company.metric
      };
    },
    validate() {
      if (this.$refs.newCompanyForm.validate()) {
        if (this.company === null) {
          this.createCompany();
        } else if (this.editing) {
          this.updateCompany();
        }
      }
    },
    async createCompany() {
      this.loading = true;
      const res = await CompaniesService.createCompany(this.newCompany);
      if (res.status === 'ok') {
        this.loading = false;
        this.$refs.newCompanyForm.resetValidation();
        this.closeDialog();
      }
      this.loading = false;
    },
    async updateCompany() {
      const res = await CompaniesService.updateCompany(this.company.id, this.newCompany);
      if (res.status === 'ok') {
        this.$refs.newCompanyForm.resetValidation();
        this.closeDialog();
      }
    },
    changeCompanyType(type) {
      this.newCompany.type = type;
    },
    changeMetric(metric) {
      this.newCompany.metric = metric;
    }
  }
};
</script>
