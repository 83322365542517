<template>
  <v-card class="fill-height elevation-0" style="border-radius: 12px;" color="#EFF2F5">
    <v-card-title class="info-card-title">
      <v-icon left color="primary">{{ icon }}</v-icon>
      <span class="title-text">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <p class="user-comments" v-if="description != null">
        <span style="font-weight: bold;">Comentarios del usuario:</span> {{ description }}
      </p>
      <v-list dense color="#EFF2F5">
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-content>
            <v-list-item-title style="font-weight: 500;" class="custom-font">{{
              item.label
            }}</v-list-item-title>
            <v-list-item-subtitle style="font-weight: 300;" class="custom-font">
              <span v-if="item.link">
                <a :href="item.link" target="_blank">{{ item.value || 'Ver adjunto' }}</a>
              </span>
              <span style="font-size: 12px; font-weight: 300" v-else>{{ item.value }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-img v-if="image" :src="image" max-width="100" class="mt-2"></v-img>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'info-card',
  props: {
    title: String,
    items: Array,
    icon: String,
    image: String,
    description: String
  }
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}

.info-card-title {
  display: flex;
  align-items: center;
}

.title-text {
  color: #104b94; /* Color primario */
  font-weight: 600;
}

/* Forzar el uso de Roboto en todo el contenido de la card */
.custom-font {
  font-family: 'Inter', sans-serif !important;
}

.user-comments {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0px;
  line-height: 1.5;
}
</style>
