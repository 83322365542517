<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <v-col>
        <h1 class="text-h5 font-weight-bold">Activación</h1>
        <p style="font-size: 14px; margin-bottom: 2px;">
          Membresías creadas y activas
        </p>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      :options.sync="options"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :disable-sort="loading"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              v-model="search"
              label="Buscar"
              :disabled="loading"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchFn"
              @keyup="cleanSearch"
              @keyup.enter="searchFn"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actual="{ item }">
        <span>{{ item.actual }}%</span>
      </template>
      <template v-slot:item.compliance="{ item }">
        <v-chip
          v-if="item.compliance >= 100"
          small
          color="green"
          class="white--text font-weight-bold"
          >{{ item.compliance }}%
        </v-chip>
        <v-chip
          v-else-if="item.compliance >= 80"
          small
          color="orange"
          class="white--text font-weight-bold"
          >{{ item.compliance }}%</v-chip
        >
        <v-chip v-else small color="red" class="white--text font-weight-bold"
          >{{ item.compliance }}%</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActivatedMembershipService from '../services/activated-memberships.service';

export default {
  name: 'ActivatedMembershipTable',
  components: {},
  props: ['type'],
  mounted() {
    this.getActivatedMemberships(1, 10, '', this.type).then(() => (this.init = true));
  },
  data() {
    return {
      init: false,
      page: 1,
      limit: 10,
      search: '',
      loading: false,
      totalItems: 0,
      options: {},
      sort: [],
      data: [
        {
          company: 'Empresa',
          users: 100,
          objective: 50,
          compliance: '50%'
        }
      ],
      headers: [
        {
          text: 'Asistencia',
          value: 'company',
          sortable: false
        },
        {
          text: 'Meta de activación',
          value: 'objective',
          sortable: false
        },
        {
          text: 'Membresías activas',
          value: 'memberships',
          sortable: false
        },
        {
          text: '% actual de activación',
          value: 'actual',
          sortable: false
        },
        {
          text: '% de cumplimiento',
          value: 'compliance',
          sortable: false
        }
      ]
    };
  },
  methods: {
    searchFn() {
      if (this.search.length >= 3) {
        this.getActivatedMemberships(this.page, this.limit, this.search, this.type);
      }
    },
    cleanSearch() {
      if (this.search.length === 0) {
        this.sort = [];
        this.getActivatedMemberships(1, 10, '', this.type);
      }
    },
    async getActivatedMemberships(page, limit, search, type) {
      this.loading = true;
      const res = await ActivatedMembershipService.getActivatedMembershipsTable(
        page,
        limit,
        search,
        type
      );
      if (res.status === 'ok') {
        this.data = res.data.data;
        this.totalItems = res.data.count;
      }
      this.loading = false;
    }
  },
  watch: {
    options: {
      handler() {
        if (this.options.page && this.init) {
          this.page = this.options.page;
          this.limit = this.options.itemsPerPage;
          this.getActivatedMemberships(this.page, this.limit, '', this.type);
        }
      },
      deep: true
    },
    type: {
      handler(newType) {
        this.page = 1;
        this.limit = 10;
        this.search = '';
        this.getActivatedMemberships(1, 10, '', newType);
      }
    }
  }
};
</script>
