<template>
  <v-card class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-dialog
      persistent
      v-model="removePlan"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <v-card class="pa-6">
        <v-card-title class="text-uppercase">
          <div>Eliminar plan</div>
        </v-card-title>
        <hr class="my-3" />
        <v-card-text class="my-0 py-0">
          <p>
            Esta acción borrará un plan para la empresa
            {{ company && company.name ? company.name : '' }}
          </p>
          <p>¿Está seguro que desea continuar con esta operación?</p>
        </v-card-text>
        <hr class="my-3" />
        <v-card-actions class="justify-end">
          <v-btn color="primary" rounded @click="addCompanyPlan">
            Aceptar
          </v-btn>
          <v-btn text color="primary" rounded @click="closeConfirmDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>Planes</div>
        <!--<v-switch
          v-if="company !== null"
          v-model="editing"
          label="Editar"
          dense
          hide-details="auto"
        ></v-switch>-->
      </v-row>
    </v-card-title>
    <hr class="my-3" />
    <v-card-text class="my-0 py-2">
      <v-form ref="newCompanyForm" v-model="formValid">
        <v-container class="pa-0">
          <!--<v-col cols="12">
            <v-select
              v-model="selectedPlan"
              label="Planes"
              :items="plans"
              @change="changePlan"
              :disabled="disabled()"
              item-value="value"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-arrow-down"
            ></v-select>
          </v-col>-->
          <div v-if="companyPlans.length > 0">
            <p class="my-5 font-weight-bold">
              Planes para {{ company && company.name ? company.name : '' }}
            </p>
            <v-data-table
              :headers="headers"
              :items="companyPlans"
              :options.sync="options"
              :loading="loadingData"
              :items-per-page="limit"
              :server-items-length="totalItems"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [5, 10]
              }"
            >
              <template v-slot:item.name="{ item }">
                <span>{{ item.name }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <span>{{ item.description }}</span>
              </template>
              <template v-slot:item.type="{ item }">
                <span>{{ item.type.toUpperCase() }}</span>
              </template>
              <template v-slot:item.visible="{ item }">
                <v-icon v-if="item.visible" color="green">mdi-check-circle</v-icon>
              </template>
              <template v-slot:item.actions="props">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      depressed
                      x-small
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog(props.item)"
                    >
                      <v-icon small dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Sacar Plan</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </v-container>
      </v-form>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn
        v-if="company === null || editing"
        :disabled="!formValid"
        color="primary"
        rounded
        @click="validate"
      >
        Guardar
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CompaniesService from '../services/companie.service';

export default {
  name: 'CompanyPlanMaintainer',
  props: ['company'],
  data() {
    return {
      headers: [
        {
          text: 'Nombre plan',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Descripción de plan',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Tipo de plan',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Estado',
          sortable: false,
          value: 'visible'
        },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions'
        }
      ],
      plans: [],
      companyPlans: [],
      totalItems: 0,
      selectedPlan: '',
      editing: false,
      loading: false,
      activePicker: null,
      formValid: false,
      limit: 10,
      page: 1,
      loadingData: false,
      options: {},
      removePlan: false
    };
  },
  mounted() {
    //console.log(this.company);
    //this.getCompanyPlans(this.company.id);
  },
  unmounted() {
    this.companyPlans = [];
    this.loading = false;
  },
  watch: {
    company: {
      handler(newCompany) {
        if (newCompany && newCompany.id) {
          this.getCompanyPlans(newCompany.id); // Fetch plans for the new company
        } else {
          this.companyPlans = []; // Clear plans if no company is selected
        }
      },
      immediate: true // Trigger the watcher immediately when the component is mounted
    }
  },
  methods: {
    openDialog() {
      this.removePlan = true;
    },
    closeConfirmDialog() {
      this.removePlan = false;
    },
    addCompanyPlan() {},
    changePlan() {},
    disabled() {
      if (this.company === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
      //this.page = 1;
      //this.limit = 1;
      //this.companyPlans = [];
    },
    validate() {
      /*if (this.$refs.newCompanyForm.validate()) {
        if (this.company === null) {
          this.createCompany();
        } else if (this.editing) {
          this.updateCompany();
        }
      }*/
    },
    async postPlanToCompany() {
      this.loading = true;
      const res = {};
      if (res.status === 'ok') {
        this.loading = false;
        //this.$refs.newCompanyForm.resetValidation();
        this.closeDialog();
      }
      this.loading = false;
    },
    async changeCompanyPlan() {
      const res = {};
      if (res.status === 'ok') {
        //this.$refs.newCompanyForm.resetValidation();
        this.closeDialog();
      }
    },
    async getCompanyPlans(companyId) {
      if (!companyId) return;
      this.loading = true;
      const res = await CompaniesService.getCompanyPlans(this.page, this.limit, companyId);
      if (res.status === 'ok') {
        this.companyPlans = res.data.plans;
        this.totalItems = res.data.count;
      }
      this.loading = false;
    }
  }
};
</script>
