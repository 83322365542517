<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Empresas</h1>
      <v-dialog
        persistent
        v-model="showDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-md-block"
            rounded
            color="primary"
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <span class="d-none d-md-block">Agregar Empresa</span>
          </v-btn>
          <v-btn
            class="d-block d-md-none"
            rounded
            color="primary"
            fab
            depressed
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </template>

        <template>
          <company-maintainer
            :company="selectedCompany"
            @update-list="getCompanies(this.page, this.limit)"
            @close-dialog="closeDialog"
          ></company-maintainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="showDialogPlans"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <company-plan-maintainer
            :company="selectedCompany"
            @update-list="getCompanies(this.page, this.limit)"
            @close-dialog="closeDialogPlans"
          ></company-plan-maintainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="deleteDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <!--<confirmation-modal
              :user="selectedUser"
              @close-dialog="closeDeleteDialog"
              @delete-user="deleteUser"
            ></confirmation-modal>-->
        </template>
      </v-dialog>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="companies"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="stringToSearch"
            label="Buscar"
            placeholder="Nombre, RUT..."
            dense
            hide-details
            outlined
            append-icon="mdi-magnify"
            @click:append="searchString"
            @keyup="cleanSearch"
            @keyup.enter="searchString"
          ></v-text-field>
        </v-col>
      </template>
      <template v-slot:item.name="{ item }">
        <span>{{ item.name }}</span>
      </template>
      <template v-slot:item.type="{ item }">
        <span>{{ parseCompanyType(item.type) }}</span>
      </template>
      <template v-slot:item.userObj="{ item }">
        <span>{{ item.userObj }}</span>
      </template>
      <template v-slot:item.membershipObj="{ item }">
        <span>{{ item.membershipObj }}</span>
      </template>
      <template v-slot:item.membershipLimit="{ item }">
        <span>{{ item.membershipLimit }}</span>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon v-if="item.active" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="openDialog(props.item)"
            >
              <v-icon small dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="orange"
              v-bind="attrs"
              v-on="on"
              @click="openDialogPlans(props.item)"
            >
              <v-icon small dark>mdi-account-cash</v-icon>
            </v-btn>
          </template>
          <span>Planes</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="purple"
              v-bind="attrs"
              v-on="on"
              @click="downloadUsersExcel(props.item)"
            >
              <v-icon small dark>mdi-download-box</v-icon>
            </v-btn>
          </template>
          <span>CSV Usuarios</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
//import UserMaintainer from '../components/UserMaintainer';
//import ConfirmationModal from '../components/ConfirmationModal';
import CompanyMaintainer from '../components/CompanyMaintainer.vue';
import CompanyPlanMaintainer from '../components/CompanyPlanMaintaner.vue';
import CompanieServices from '../services/companie.service';
import Overlay from '../components/Overlay.vue';

export default {
  components: {
    CompanyMaintainer,
    CompanyPlanMaintainer,
    //ConfirmationModal,
    Overlay
  },
  data() {
    return {
      loadingOverlay: false,
      stringToSearch: null,
      showDialog: false,
      showDialogPlans: false,
      deleteDialog: false,
      loadingData: true,
      options: {},
      companies: [],
      selectedCompany: null,
      activatedCompanie: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Nombre',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Tipo de negocio',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Objetivo de usuarios',
          sortable: false,
          value: 'userObj'
        },
        {
          text: 'Objetivo de activación',
          sortable: false,
          value: 'membershipObj'
        },
        {
          text: 'Límite de membresías gratuitas',
          sortable: false,
          value: 'membershipLimit'
        },
        {
          text: 'Activo',
          sortable: false,
          value: 'active'
        },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions'
        }
      ]
    };
  },
  mounted() {
    // this.getUsers(this.page, this.limit);
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getCompanies(this.page, this.limit, this.stringToSearch);
      },
      deep: true
    }
  },
  methods: {
    openDialog(companie) {
      this.showDialog = true;
      this.selectedCompany = companie;
    },
    openDialogPlans(companie) {
      this.showDialogPlans = true;
      this.selectedCompany = companie;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedCompany = null;
      this.getCompanies(this.page, this.limit, this.stringToSearch);
    },
    closeDialogPlans() {
      this.showDialogPlans = false;
      setTimeout(() => {
        this.selectedCompany = null;
        this.getCompanies(this.page, this.limit, this.stringToSearch);
      }, 500); // Delay of 300ms to allow the dialog to close
    },
    triggerDeleteDialog(companie) {
      this.deleteDialog = true;
      this.selectedCompany = companie;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.selectedCompany = null;
    },
    async getCompanies(page, limit, search) {
      this.loadingData = true;
      const res = await CompanieServices.getCompanies(page, limit, search);
      if (res.status === 'ok') {
        this.companies = res.data.companies;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    async deleteCompanies() {
      const res = {};
      if (res.status === 'ok') {
        this.closeDeleteDialog();
        this.getCompanies(this.page, this.limit, '');
      }
    },
    showPolicy(url) {
      window.open(url);
    },
    formatDate(date) {
      try {
        date = new Date(date);
        let day = date.getDate();
        day = day >= 10 ? day : `0${day}`;
        let month = date.getMonth() + 1;
        month = month >= 10 ? month : `0${month}`;
        let year = date.getFullYear();
        return `${day}/${month}/${year}`;
      } catch (error) {
        return '';
      }
    },
    async searchString() {
      if (this.stringToSearch.length >= 3) {
        this.getCompanies(this.page, this.limit, this.stringToSearch);
      }
    },
    async cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.getCompanies(this.page, this.limit, this.stringToSearch);
      }
    },
    parseCompanyType(type) {
      switch (type) {
        case 'eb':
          return 'Employee Benefit';
        case 'b2c':
          return 'B2C';
        case 'b2b2c':
          return 'B2B2C';
        case 'assistance':
          return 'Asistencia Integrada';
        case 'dxp':
          return 'Dcto x planilla';
        case 'broker':
          return 'Broker';
        default:
          return '-';
      }
    },
    async downloadUsersExcel(company) {
      try {
        if (!company) return;
        this.loadingOverlay = true;
        const res = await CompanieServices.downloadUsersFile(company.id);
        if (res.status !== 'ok') {
          throw new Error('Error al descargar el archivo');
        }
      } catch (err) {
        this.$toast.error('Ocurrió un error al descargar el archivo Excel');
      } finally {
        this.loadingOverlay = false;
      }
    }
  }
};
</script>
