<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Usuarios</h1>

      <v-btn class="d-none d-md-block" rounded color="primary" depressed @click="goToCreateUser">
        <span class="d-none d-md-block">Agregar Usuario</span>
      </v-btn>
      <v-btn
        class="d-block d-md-none"
        rounded
        color="primary"
        fab
        depressed
        x-small
        @click="goToCreateUser"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>

      <v-dialog
        persistent
        v-model="showDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <user-maintainer
            :user="selectedUser"
            @update-list="getUsers(this.page, this.limit)"
            @close-dialog="closeDialog"
          ></user-maintainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="deleteDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <confirmation-modal
            :user="selectedUser"
            @close-dialog="closeDeleteDialog"
            @delete-user="deleteUser"
          ></confirmation-modal>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="petsDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <pets-list :user="selectedUser" @close-dialog="closePetsDialog"></pets-list>
        </template>
      </v-dialog>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="stringToSearch"
            label="Buscar"
            placeholder="Nombre, correo, RUT..."
            dense
            hide-details
            outlined
            append-icon="mdi-magnify"
            @click:append="searchString"
            @keyup="cleanSearch"
            @keyup.enter="searchString"
          ></v-text-field>
        </v-col>
      </template>
      <template v-slot:item.name="{ item }">
        <span>{{ item.name }} {{ item.surname }}</span>
        <span v-if="item.role == 'admin'"
          ><v-chip class="ml-2" color="primary" x-small outlined> admin </v-chip></span
        >
      </template>
      <template v-slot:item.rut="{ item }">
        <span>{{ item.rut | rut }}</span>
      </template>
      <template v-slot:item.birthday="{ item }">
        <span v-if="item.birthday">{{ formatDate(item.birthday) }}</span>
      </template>
      <template v-slot:item.role="{ item }">
        <v-chip-group>
          <v-chip small outlined color="primary" class="white--text font-weight-bold">{{
            item.role
          }}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.hasActiveMemberships="{ item }">
        <v-icon v-if="item.hasActiveMemberships" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.actions="props">
        <!-- <v-btn class="mx-2" fab dark x-small color="pink" @click="triggerDeleteDialog(props.item)">
          <v-icon small dark>mdi-delete-forever</v-icon>
        </v-btn> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="openDialog(props.item)"
            >
              <v-icon small dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top v-if="props.item.role == 'subscriber'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="addPet(props.item)"
            >
              <v-icon small dark>mdi-wallet-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar membresía</span>
        </v-tooltip>
        <v-tooltip top v-if="props.item.role == 'subscriber'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="orange"
              v-bind="attrs"
              v-on="on"
              @click="goToMemberships(props.item)"
            >
              <v-icon small dark>mdi-wallet-membership</v-icon>
            </v-btn>
          </template>
          <span>Ver membresías</span>
        </v-tooltip>
        <v-tooltip top v-if="props.item.role == 'subscriber'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              color="purple"
              v-bind="attrs"
              v-on="on"
              @click="goToPets(props.item)"
            >
              <v-icon small dark>mdi-paw</v-icon>
            </v-btn>
          </template>
          <span>Ver mascotas</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UsersService from '../services/users.service';
import UserMaintainer from '../components/UserMaintainer';
import ConfirmationModal from '../components/ConfirmationModal';
import PetsList from '../components/PetsList';

export default {
  components: {
    UserMaintainer,
    ConfirmationModal,
    PetsList
  },
  data() {
    return {
      stringToSearch: null,
      showDialog: false,
      deleteDialog: false,
      petsDialog: false,
      loadingData: true,
      options: {},
      users: [],
      memberships: [],
      selectedUser: null,
      activatedUser: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Nombre',
          sortable: false,
          value: 'name'
        },
        {
          text: 'RUT',
          sortable: false,
          value: 'rut'
        },
        {
          text: 'F. nacimiento',
          sortable: false,
          value: 'birthday'
        },
        {
          text: 'Correo',
          sortable: false,
          value: 'email'
        },
        {
          text: 'Teléfono',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Activo',
          sortable: false,
          value: 'hasActiveMemberships'
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    // this.getUsers(this.page, this.limit);
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getUsers(this.page, this.limit);
      },
      deep: true
    }
  },
  methods: {
    openDialog(user) {
      this.selectedUser = user;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedUser = null;
      this.getUsers(this.page, this.limit);
    },
    triggerDeleteDialog(user) {
      this.deleteDialog = true;
      this.selectedUser = user;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.selectedUser = null;
    },
    openPetsDialog(user) {
      this.petsDialog = true;
      this.selectedUser = user;
    },
    closePetsDialog() {
      this.petsDialog = false;
      this.selectedUser = null;
    },
    async getUsers(page, limit) {
      this.loadingData = true;
      const res = await UsersService.getUsers(
        page,
        limit,
        this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null
      );
      if (res.status === 'ok') {
        this.users = res.data.users;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    async deleteUser() {
      const res = await UsersService.deleteUser(this.selectedUser._id);
      if (res.status === 'ok') {
        this.closeDeleteDialog();
        this.getUsers(this.page, this.limit);
      }
    },
    showPolicy(url) {
      window.open(url);
    },
    formatDate(date) {
      try {
        date = new Date(date);
        let day = date.getDate();
        day = day >= 10 ? day : `0${day}`;
        let month = date.getMonth() + 1;
        month = month >= 10 ? month : `0${month}`;
        let year = date.getFullYear();
        return `${day}/${month}/${year}`;
      } catch (error) {
        return '';
      }
    },
    goToCreateUser() {
      this.$router.push('/users/create-user');
    },
    goToPets(user) {
      this.$router.push(`/pets/${user._id}`);
    },
    goToMemberships(user) {
      this.$router.push(`memberships/${user._id}`);
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length == 0
      ) {
        this.getUsers(this.page, this.limit, this.search);
      }
    },
    cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.getUsers(this.page, this.limit, this.search);
      }
    },
    addPet(user) {
      this.$router.push({ name: 'AddPet', params: { userId: user._id } });
    }
  }
};
</script>
