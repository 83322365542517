<template>
  <div>
    <!--<DataCompliance />-->
    <template>
      <v-row class="py-5" style="justify-content: space-between; align-items: center">
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-select
            v-model="selectedCompany"
            label="Tipo de empresa"
            :items="companyTypes"
            :disabled="loading"
            @change="changeCompanyType"
            item-value="value"
            placeholder=""
            dense
            hide-details
            outlined
            append-icon="mdi-arrow-down"
          ></v-select>
        </v-col>
      </v-row>
    </template>
    <CompanyUsersTable :type="selectedCompany" />
    <ActivatedMembershipsTable :type="selectedCompany" />
  </div>
</template>

<script>
import ActivatedMembershipsTable from '../components/ActivatedMembershipsTable.vue';
import CompanyUsersTable from '../components/CompanyUsersTable.vue';
//import DataCompliance from '../components/DataCompliance.vue';

export default {
  components: {
    CompanyUsersTable,
    ActivatedMembershipsTable
    //DataCompliance
  },
  mounted() {},
  data() {
    return {
      loading: false,
      selectedCompany: 'assistance',
      companyTypes: [
        { value: 'assistance', text: 'Asistencia' },
        { value: 'eb', text: 'Employee Benefit' },
        { value: 'dxp', text: 'Dcto x Planilla' }
      ]
    };
  },
  methods: {
    changeCompanyType(value) {
      this.selectedCompany = value;
    }
  }
};
</script>
