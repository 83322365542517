import axios from 'axios';
const backendUrl = process.env.VUE_APP_ADMIN_URL;

export default class CompanyUsersTableService {
  static async getCompanyUsersTable(page, limit, search, type) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let params = { page, limit, type };
      if (search) {
        params = { ...params, search: search };
      }
      const response = await axios.get(`${backendUrl}/company-users/data`, {
        params,
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      console.log(response);
      if (response.data.status === 'ok') {
        return response.data;
      }
    } catch (err) {
      return { status: 'error' };
    }
  }
}
