var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Planes")]),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"rounded":"","color":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Agregar Plan")])]),_c('v-btn',_vm._g(_vm._b({staticClass:"d-block d-md-none",attrs:{"rounded":"","color":"primary","fab":"","depressed":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[[_c('plan-maintainer',{attrs:{"plan":_vm.selectedPlan},on:{"update-list":function($event){return _vm.getPlans(this.page, this.limit)},"close-dialog":_vm.closeDialog}})]],2),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[[_c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"text-uppercase"},[_c('div',[_vm._v("Eliminar plan "+_vm._s(_vm.selectedPlan != null ? _vm.selectedPlan.name : ''))])]),_c('hr',{staticClass:"my-3"}),_c('v-card-text',{staticClass:"my-0 py-0"},[_c('p',[_vm._v("¿Está seguro de que desea eliminar el plan?")])]),_c('hr',{staticClass:"my-3"}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.deletePlan(_vm.selectedPlan)}}},[_vm._v(" Sí, eliminar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","rounded":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")])],1)],1)]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.plans,"loading":_vm.loadingData,"options":_vm.options,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 py-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","disabled":_vm.loadingData,"placeholder":"","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchFn,"keyup":[_vm.cleanSearch,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFn.apply(null, arguments)}]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.plan",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"font-weight-bold ma-0"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.annualPrice",fn:function(ref){
    var item = ref.item;
return [(item.annualPrice != null)?_c('div',[(item.currency == 'CLP')?_c('span',[_vm._v(_vm._s(_vm._f("clpCurrency")(item.annualPrice)))]):_vm._e(),(item.currency == 'UF')?_c('span',[_vm._v(_vm._s(_vm._f("clpCurrency")(item.annualPrice)))]):_vm._e()]):_vm._e()]}},{key:"item.monthlyPrice",fn:function(ref){
    var item = ref.item;
return [(item.monthlyPrice != null)?_c('div',[(item.currency == 'CLP')?_c('span',[_vm._v(_vm._s(_vm._f("clpCurrency")(item.monthlyPrice)))]):_vm._e(),(item.currency == 'UF')?_c('span',[_vm._v(_vm._s(_vm._f("clpCurrency")(item.monthlyPrice)))]):_vm._e()]):_vm._e()]}},{key:"item.animalType",fn:function(ref){
    var item = ref.item;
return [(item.animalType === 'cat' || item.animalType === null)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cat ")]),_vm._v(" Gato")],1):_vm._e(),(item.animalType === 'dog' || item.animalType === null)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"orange"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-dog ")]),_vm._v(" Perro")],1):_vm._e()]}},{key:"item.metrics",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.metrics.actives)+" / "+_vm._s(item.metrics.total))])]}},{key:"item.visible",fn:function(ref){
    var item = ref.item;
return [(item.visible)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e(),(!item.visible)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"pink"},on:{"click":function($event){return _vm.triggerDeleteModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-delete-forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.navitageToPlan(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Revisar")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }