import axios from 'axios';
import FileUtils from '../utils/file.utils';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const adminUrlV3 = process.env.VUE_APP_BACKEND_URL_V3;
//const adminUrlv3 = process.env.VUE_APP_ADMIN_URL_V3;
const urlV3 = process.env.VUE_APP_BACKEND_URL_V3;

export default class MembershipsService {
  static async getMemberships(page, limit, query, sort, userId, search, supplierId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/memberships?page=${page}&limit=${limit}`;
      // console.log('QUERY:', query.length);
      if (userId != null && userId.length != 0) {
        url = url + `&userId=${userId}`;
      }
      if (query != null && query.length != 0) {
        url = url + `&status=${query}`;
      }
      if (sort != null && sort.length != 0) {
        url = url + `&sort=${sort.join(',')}`;
      }
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      if (supplierId != null && supplierId.length != 0) {
        url = url + `&supplierId=${supplierId}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        const memberships = response.data.data.memberships.map(
          ({ PlanModel: plan, PetModel: pet, ...rest }) => ({
            plan,
            pet,
            ...rest
          })
        );
        response.data.data.memberships = memberships;
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateMembership(membershipId, membership) {
    try {
      if (membership.contractFile) {
        membership.contractBase64 = await FileUtils.getBase64(membership.contractFile);
      }
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const data = membership;
      const response = await axios.put(`${adminUrl}/memberships/${membershipId}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async activateMembership(
    membership,
    policyNumber,
    policyFile,
    contractFile,
    notificationEmail
  ) {
    try {
      let policyBase64;
      let contractBase64;
      if (policyFile) {
        policyBase64 = await FileUtils.getBase64(policyFile);
      }
      if (contractFile) {
        contractBase64 = await FileUtils.getBase64(contractFile);
      }
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const data = {
        policyNumber,
        policyBase64,
        contractBase64,
        notificationEmail
      };
      const response = await axios.put(`${adminUrl}/memberships/activate/${membership.id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async deactivateMembership(membershipId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(
        `${adminUrl}/memberships/deactivate/${membershipId}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getExportedCsv(page, limit, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${adminUrl}/memberships/export`;

      // Configurar los parámetros de consulta
      const params = {};
      if (companyId) {
        params.companyId = companyId;
      }
      if (status) {
        params.status = status;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: params,
        responseType: 'blob'
      });

      if (response.status === 200) {
        // Crear un Blob para el archivo descargado
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });

        // Crear un enlace para descargar el archivo
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        // Obtener el nombre del archivo del encabezado 'Content-Disposition'
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'memberships.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
          }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Liberar el objeto URL creado
        window.URL.revokeObjectURL(downloadUrl);

        return { status: 'ok' };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      return { status: 'error' };
    }
  }

  static async getSupplierExcel(supplierId, date) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${adminUrl}/memberships/suppliers/${supplierId}${date != null ? `?date=${date}` : ''}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob'
        }
      );
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
  static async getWeeklyExcel(supplierId, since, today) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${adminUrl}/memberships/suppliers/weekly/${supplierId}?since=${since}&today=${today}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob'
        }
      );
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
  // Services redemptions
  static async createServiceRedemption(serviceRedemptionData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/services/redeem`, serviceRedemptionData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateServiceRedemption(id, serviceRedemptionData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/services/redeem/${id}`, serviceRedemptionData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createPetAndMembership(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/memberships/create/pet-membership`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', message: response.data.message };
      }
    } catch (error) {
      console.error('Error en createPetAndMembership:', error);
      return { status: 'error', message: error.message };
    }
  }

  static async getDxpMemberships(page, limit, search, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrlV3}/membership/dxp-memberships?page=${page}&pageSize=${limit}`;

      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      if (response.data.status === 'ok') {
        const memberships = response.data.data.map(
          ({ id, userName, email, petName, planName, companyName, companyId, species }) => ({
            id,
            userName,
            email,
            petName,
            planName,
            companyName,
            companyId,
            species
          })
        );
        return {
          status: 'ok',
          data: {
            memberships,
            total: response.data.data.total,
            currentPage: response.data.data.currentPage,
            totalPages: response.data.data.totalPages
          }
        };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.error('Error fetching dxp memberships:', error);
      return { status: 'error' };
    }
  }

  static async getMembershipDetails(membershipId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      const url = `${adminUrlV3}/membership/dxp-memberships/${membershipId}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      if (response.data.status === 'ok') {
        const { rut, startDate, daysDiff, price, priceWithoutTax, ref } = response.data.data;

        return {
          status: 'ok',
          data: {
            rut,
            startDate,
            daysDiff,
            price,
            priceWithoutTax,
            ref
          }
        };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.error('Error fetching membership details:', error);
      return { status: 'error' };
    }
  }

  static async approveRequest(membershipId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      const response = await axios.post(
        `${adminUrlV3}/membership/approve/${membershipId}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );

      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return {
          status: 'error',
          message: response.data.message || 'Error al aprobar solicitud.'
        };
      }
    } catch (error) {
      console.error('Error approving request:', error);
      return { status: 'error', message: 'Error al aprobar solicitud.' };
    }
  }

  static async rejectRequest(membershipId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      const response = await axios.post(
        `${adminUrlV3}/membership/reject/${membershipId}`,
        {}, // Cuerpo vacío ya que solo necesitas el ID en la ruta
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );

      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return {
          status: 'error',
          message: response.data.message || 'Error al rechazar solicitud.'
        };
      }
    } catch (error) {
      console.error('Error rejecting request:', error);
      return { status: 'error', message: 'Error al rechazar solicitud.' };
    }
  }

  static async getActiveDxpMemberships(page, limit, search, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrlV3}/membership/get-dxp-active-memberships?page=${page}&pageSize=${limit}`;

      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }

      if (companyId) {
        url += `&companyId=${companyId}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      if (response.data.status === 'ok') {
        const memberships = response.data.data.data.map(
          ({ id, userName, email, petName, planName, companyName, companyId, species }) => ({
            id,
            userName,
            email,
            petName,
            planName,
            companyName,
            companyId,
            species
          })
        );
        return {
          status: 'ok',
          data: {
            memberships,
            total: response.data.total,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages
          }
        };
      } else {
        return { status: 'error', message: response.data.message || 'Error fetching memberships.' };
      }
    } catch (error) {
      console.error('Error fetching active dxp memberships:', error);
      return { status: 'error', message: 'Error fetching memberships.' };
    }
  }

  static async getExportedDxpCsv(status, companyId, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrlV3}/export/export-dxp-memberships`;

      const params = new URLSearchParams();
      params.append('status', status);
      if (companyId) params.append('companyId', companyId);
      if (search) params.append('search', search);

      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob'
      });

      if (response.status === 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.error('Error fetching CSV:', error);
      return { status: 'error' };
    }
  }
  static async getExportedMembershipsExcel(companyId, status, country) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${urlV3}/membership/export`;
      const params = {};
      if (companyId && companyId.length !== 0) {
        params.companyId = companyId;
      }
      if (status && status.length !== 0) {
        params.status = status;
      }
      params.country = country;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: params,
        responseType: 'blob'
      });

      if (response.status === 200) {
        // Crear un Blob a partir de la respuesta
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        // Obtener el nombre de archivo desde el encabezado 'Content-Disposition', si está presente
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'memberships.xlsx';
        if (contentDisposition) {
          let fileNameMatch = contentDisposition.match(/filename\*?=(['"])(.*?)\1/);
          if (fileNameMatch && fileNameMatch.length >= 3) {
            fileName = fileNameMatch[2];
          } else {
            // Intentar con otro patrón
            fileNameMatch = contentDisposition.match(/filename\*?=([^;]+)/);
            if (fileNameMatch && fileNameMatch.length >= 2) {
              fileName = fileNameMatch[1].trim();
            }
          }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(downloadUrl);

        return { status: 'ok', success: true };
      } else {
        throw new Error(`Error al descargar el archivo: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      throw error;
    }
  }
}
