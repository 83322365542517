<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="72%" :scrim="false" transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          outlined
          color="green"
          class="white--text font-weight-bold"
          v-bind="attrs"
          v-on="on"
          >Ver</v-btn
        >
      </template>
      <v-card>
        <div
          class="d-flex flex-row justify-space-between pa-4 bg-grey"
          color="primary"
          name="refund-mainteiner"
        >
          <v-btn icon dark @click="dialog = false"> </v-btn>
          <span class="text-h6 font-weight-bold">Solicitud de reembolso </span>
          <v-btn icon dark @click="dialog = false">
            <v-icon color="#1B1D21">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider color="#EAEEF4"></v-divider>
        <!-- ! -->
        <v-list lines="two" subheader class="pa-4">
          <!-- Container -->
          <!-- Sección de Tarjetas de Información -->
          <v-container fluid>
            <!-- Primera Fila -->
            <v-row>
              <!-- Tarjeta de Usuario -->
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  title="Usuario"
                  :items="userInfoItems"
                  icon="mdi-account"
                  class="flex-grow-1"
                ></info-card>
              </v-col>

              <!-- Tarjeta de Mascota -->
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  v-if="petData"
                  title="Mascota"
                  :items="petInfoItems"
                  icon="mdi-paw"
                  class="flex-grow-1"
                ></info-card>
                <v-alert v-else type="warning" outlined>
                  Sin información de mascota
                </v-alert>
              </v-col>

              <!-- Tarjeta de Banco -->
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  v-if="userBankDetails"
                  title="Banco"
                  :items="bankInfoItems"
                  icon="mdi-bank"
                  class="flex-grow-1"
                ></info-card>
                <v-alert v-else type="warning" outlined>
                  Sin información bancaria
                </v-alert>
              </v-col>
            </v-row>

            <!-- Segunda Fila -->
            <v-row>
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  v-if="planData"
                  title="Plan"
                  :items="planInfoItems"
                  icon="mdi-card-account-details-outline"
                  class="flex-grow-1"
                ></info-card>
                <v-alert v-else type="warning" outlined>
                  Sin información del plan
                </v-alert>
              </v-col>
              <!-- Tarjeta de Servicio -->
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  v-if="service"
                  :title="service.name"
                  :items="serviceInfoItems"
                  class="flex-grow-1"
                ></info-card>
                <v-alert v-else type="warning" outlined>
                  Sin información del servicio
                </v-alert>
              </v-col>

              <!-- Tarjeta de Adjuntos -->
              <v-col cols="12" md="4" class="d-flex">
                <info-card
                  v-if="files"
                  title="Adjuntos"
                  :description="userComments"
                  :items="attachmentsInfoItems"
                  icon="mdi-paperclip"
                  class="flex-grow-1"
                ></info-card>
                <v-alert v-else type="warning" outlined>
                  Sin adjuntos
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-list>
        <!-- ! -->
        <v-divider></v-divider>

        <div class="d-flex mt-6 justify-center">
          <span class="text-h6 font-weight-bold">Cambiar a: </span>
        </div>

        <v-list lines="two" subheader class="pa-4">
          <div class="d-flex flex-row justify-center">
            <!-- ACCIONES -->
            <div class="d-flex flex-column align-center">
              <div class="d-flex flex-row">
                <v-form @submit.prevent="watchData" class="d-flex flex-column">
                  <v-radio-group v-model="selectedOption" row>
                    <v-radio
                      v-for="(status, index) in statuses"
                      :key="index"
                      :label="status.text"
                      :value="status"
                      :color="status.color"
                      class="custom-font"
                      style="font-weight: 300;"
                      dense
                    ></v-radio>
                  </v-radio-group>

                  <!-- Mensaje de consejo -->
                  <p
                    v-if="selectedOption.value != null"
                    style="font-size: 12px; font-weight: 300"
                    class="text-center custom-font"
                  >
                    {{ selectedOption.adviceMsg }}
                  </p>

                  <!-- Campo de Observaciones -->
                  <div
                    style="border-radius: 8px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px; margin-bottom: 12px"
                  >
                    <v-text-field
                      class="custom-placeholder"
                      style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;font-weight: 300;"
                      v-model="inputObservations"
                      hide-details
                      placeholder="Observaciones"
                      maxlength="300"
                    />
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </v-list>
        <!-- ! -->
        <v-divider></v-divider>
        <v-list lines="two" subheader>
          <v-expansion-panels v-model="activePanel" flat class="pa-4">
            <v-expansion-panel elevation="0">
              <v-expansion-panel-header>
                <v-row>
                  <span class="text-h6" style="font-weight: 600;">Historial de cambios</span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="elevation-0">
                  <v-card-text>
                    <template
                      v-if="changelogs.length > 0"
                      class="d-flex flex-column justify-center align-center"
                    >
                      <div class="d-flex flex-column align-center">
                        <p class="font-weight-bold text-center">Más reciente</p>
                      </div>
                      <v-timeline>
                        <v-timeline-item
                          v-for="changelog in changelogs"
                          :key="changelog.id"
                          :color="changelog.status.highlightColor"
                        >
                          <div class="d-flex flex-column align-center">
                            <div class="d-flex flex-column align-center pr-2">
                              <div class="font-weight-medium">
                                {{ changelog.status.name }}&nbsp;
                              </div>

                              <span style="font-size: 12px"
                                >({{ parseDate(new Date(changelog.createdAt)) }} -
                                {{ new Date(changelog.createdAt).getHours() }}:{{
                                  new Date(changelog.createdAt).getMinutes()
                                }})</span
                              >
                            </div>

                            <div>
                              <div
                                v-if="changelog.observations != null"
                                class="d-flex flex-column align-center"
                              >
                                <span style="font-size: 14px"><u>Observaciones:</u></span>
                                <p style="font-size: 13px; text-align:center">
                                  "{{ changelog.observations || 'Sin observaciones' }}"
                                </p>
                              </div>

                              <div v-else>
                                <p style="font-size: 12px">
                                  Sin observaciones
                                </p>
                              </div>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                      <p class="font-weight-bold text-center">Más antigua</p>
                    </template>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
          <!-- Mostrar el formulario solo si el estado seleccionado es "Aprobado" -->

          <div class="px-5 py-5" v-if="selectedOption.value === 400">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h3 class="font-weight-bold">Registro aprobado del reembolso</h3>
                </v-col>
              </v-row>

              <v-row>
                <!-- Input de fecha -->
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menuRefundApprovedDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        style="border-radius: 8px; border: solid #CFD3D4 1px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px;"
                      >
                        <div class="d-flex align-center">
                          <div style="margin-right: 20px;">
                            <CalendarIcon />
                          </div>
                          <div class="flex-row">
                            <v-text-field
                              v-model="refundApprovedDate"
                              readonly
                              placeholder="¿Cuándo fue usado?"
                              hide-details
                              class="custom-placeholder"
                              v-bind="attrs"
                              v-on="on"
                              style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </template>

                    <v-date-picker
                      v-model="refundApprovedDate"
                      locale="es"
                      @input="menuRefundApprovedDate = false"
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Input de anotaciones -->
                <v-col cols="12" md="6">
                  <div
                    style="border-radius: 8px; border: solid #CFD3D4 1px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px;"
                  >
                    <div class="flex-row">
                      <v-text-field
                        v-model="refundAnnotations"
                        plain
                        hide-details
                        single-line
                        placeholder="Anotaciones opcionales para Pawer"
                        class="custom-placeholder"
                        style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <!-- Input de gasto del usuario -->
                <v-col cols="12" md="6">
                  <div
                    style="border-radius: 8px; border: solid #CFD3D4 1px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px;"
                  >
                    <div class="flex-row">
                      <v-text-field
                        v-model="userExpenseFormatted"
                        plain
                        model-value="10.000"
                        hide-details
                        single-line
                        placeholder="$ Gasto del usuario"
                        class="custom-placeholder"
                        type="text"
                        style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;"
                      />
                    </div>
                  </div>
                </v-col>

                <!-- Input de monto reembolsado -->
                <v-col cols="12" md="6">
                  <div
                    style="border-radius: 8px; border: solid #CFD3D4 1px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px;"
                  >
                    <div class="flex-row">
                      <v-text-field
                        v-model="refundAmountFormatted"
                        plain
                        model-value="10.000"
                        hide-details
                        single-line
                        placeholder="$ Monto a reembolsar"
                        class="custom-placeholder"
                        type="text"
                        style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px;"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h3 class="font-weight-bold">Registro en ficha de la mascota</h3>
                </v-col>
              </v-row>

              <!-- Sección duplicable -->
              <div v-for="(entry, index) in entries" :key="index">
                <v-row v-if="showRadioButtons">
                  <v-col cols="12">
                    <v-radio-group v-model="entry.selectedRadioOption" row>
                      <v-radio
                        v-for="option in radioOptions"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- Input de fecha -->
                  <v-col cols="12" md="6">
                    <div
                      style="border-radius: 8px; border: solid #CFD3D4 1px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px;"
                      :style="{ backgroundColor: '#EFF2F5' }"
                    >
                      <div class="d-flex align-center">
                        <div style="margin-right: 20px;">
                          <CalendarIcon />
                        </div>
                        <div class="flex-row">
                          <v-text-field
                            v-model="refundApprovedDate"
                            readonly
                            placeholder="¿Cuándo fue usado?"
                            hide-details
                            class="custom-bg-input"
                            style="margin: 0px; padding-top: 5px; padding-bottom: 5px; font-size: 14px; background-color: #EFF2F5;"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <!-- Frecuencia de postura (opcional) -->
                  <v-col cols="12" md="6">
                    <div style="background-color: #EFF2F5; border-radius: 8px; padding: 8px 16px;">
                      <v-select
                        v-model="entry.postureFrequency"
                        placeholder="Frecuencia de postura (opcional)"
                        class="custom-placeholder elevation-0 custom-select"
                        hide-details
                        plain
                        style="margin: 0; padding: 5px 0; font-size: 14px; color: #ABAFB1;"
                        :items="['Diario', 'Semanal', 'Mensual', 'Anual', 'No aplica']"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>

                <!-- Anotaciones para el usuario -->
                <v-row class="my-4">
                  <v-col cols="12">
                    <div
                      style="border-radius: 8px; border: solid #CFD3D4 1px; padding: 8px 16px; width: 100%;"
                    >
                      <v-textarea
                        v-model="entry.userNotes"
                        placeholder="Anotaciones para el usuario (opcional)"
                        class="custom-placeholder"
                        style="margin: 0; padding: 5px 0; font-size: 14px;"
                        plain
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- Botón para agregar vacuna o desparasitación -->
                <v-row v-if="buttonText && entries.length < 2">
                  <v-col cols="12">
                    <v-btn
                      block
                      :style="{ backgroundColor: '#F4F7FA', color: '#000' }"
                      @click="addEntry"
                      elevation="0"
                      class=" custom-button mt-2"
                    >
                      <v-icon left style="color: #6A7682;">mdi-plus</v-icon>
                      <span class="custom-button" style="color: #6A7682; border-radius: 8px;">{{
                        buttonText
                      }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </v-list>
        <v-row></v-row>
        <!-- ! -->
        <div style="height: 50px"></div>
        <v-container class="pa-4">
          <v-row justify="center">
            <v-btn
              color="primary"
              class="white--text"
              @click="saveRecords"
              style="width: 50%; border-radius: 10px;"
            >
              Guardar registro
            </v-btn>
          </v-row>
        </v-container>
        <div style="height: 20px"></div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="updateChangelogError" :timeout="5000">
      {{ updateChangelogErrorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="updateChangelogError = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="updateChangelogOk" :timeout="5000">
      {{ updateChangelogOkMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="updateChangelogOk = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <overlay :value="loadingOverlay"></overlay>
  </v-row>
</template>

<script>
import RefundServices from '../services/refunds.service';
import Overlay from '../components/Overlay.vue';
import InfoCard from './InfoCard.vue';
import MiscUtils from '../utils/misc.utils';
import CalendarIcon from '../assets/calendar-icon.vue';
import DateUtils from '../utils/date.utils';
import CurrencyUtils from '../utils/currency.utils';
export default {
  components: {
    Overlay,
    InfoCard,
    CalendarIcon
  },
  data() {
    return {
      activePanel: null, // Track the active panel index
      changelogFormActivated: false,
      refundData: null,
      userComments: null,
      user: null,
      planData: null,
      petData: null,
      service: null,
      files: [],
      changelogs: [],
      userBankDetails: null,
      inputObservations: '',
      refundAmount: '',
      selectedOption: {
        text: '',
        value: 0,
        color: '',
        icon: '',
        adviceMsg: ''
      },
      // *
      loadingOverlay: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      updateChangelogError: false,
      updateChangelogErrorMsg: '',
      updateChangelogOk: false,
      updateChangelogOkMsg: '',
      statuses: [
        {
          text: 'Registrado',
          value: 0,
          color: '#104B94',
          icon: 'mdi-clock-time-nine-outline',
          adviceMsg: 'Solicitud enviada por el usuario, aún no revisada por un admin'
        },
        {
          text: 'En revisión',
          value: 100,
          color: '#CC7A00',
          icon: 'mdi-clock-time-nine-outline',
          adviceMsg: 'Un administrador revisará la solicitud actual'
        },
        {
          text: 'Corregir',
          value: 200,
          color: '#5348CC',
          icon: 'mdi-account-edit',
          adviceMsg: 'El usuario deberá actualizar la información de su solicitud'
        },
        {
          text: 'Rechazar',
          value: 300,
          color: '#A91A23',
          icon: 'mdi-close-thick',
          adviceMsg: 'Se rechaza la solicitud de reembolso'
        },
        {
          text: 'Aprobar',
          value: 400,
          color: '#1CA762',
          icon: 'mdi-check-bold',
          adviceMsg: 'El monto de reembolso debe ser abonado en la cuenta del usuario'
        }
      ],
      refundApprovedDate: '',
      refundAnnotations: '',
      userExpense: '',
      petRecordDate: '',
      postureFrequency: null,
      userNotes: '',
      menuRefundApprovedDate: false,
      menuPetRecordDate: false,
      showRadioButtons: false,
      radioOptions: [],
      selectedRadioOption: null,
      buttonText: '',
      entries: [
        {
          selectedRadioOption: null,
          refundApprovedDate: '',
          menuRefundApprovedDate: false,
          postureFrequency: null,
          userNotes: ''
        }
      ]
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshParent: {
      type: Function,
      required: true
    }
  },

  methods: {
    formatNumber(num) {
      if (!num) return '';
      num = num.replace(/[^0-9]/g, '');
      return `$${CurrencyUtils.formatPriceFromNumber(num)}`;
    },
    parseServiceRecordData() {
      return {
        membershipId: this.petData.membershipId,
        servicePlanId: this.service.servicePlanId,
        userId: this.refundData.refund.userId,
        refundRequestId: this.refundData.refund.id,
        description: this.refundAnnotations,
        refundable: true,
        refundedAmount: parseInt(CurrencyUtils.cleanCurrencyFormat(this.refundAmount)),
        userSpends: parseInt(CurrencyUtils.cleanCurrencyFormat(this.userExpense)),
        usedAt: this.refundApprovedDate,
        operationType: 'refund'
      };
    },
    parseDigitalRecord() {
      let digitalRecords = [];
      this.entries.map(entry => {
        digitalRecords.push({
          title: this.service.name,
          userDescription: entry.userNotes,
          adminDescription: `${this.service.name} - ${entry?.selectedRadioOption ??
            'sin tipo'} - ${entry?.postureFrequency ?? 'sin frecuencia'} - ${
            this.refundAnnotations
          }`,
          date: this.refundApprovedDate,
          petId: this.refundData.refund.petId,
          expenses: parseInt(CurrencyUtils.cleanCurrencyFormat(this.userExpense))
        });
      });
      return digitalRecords;
    },
    parseDate(date) {
      return DateUtils.formatToChileanDate(date);
    },
    async watchData() {
      let refund = this.data;

      // Validar que 'changelogs' y 'status' existen
      if (!this.data.changelogs || !this.data.changelogs.status) {
        console.error('No se encontraron registros de estado en changelogs');
        this.loadingOverlay = false;
        return;
      }

      let lastChangelogStatus = this.data.changelogs.status; // Accede a 'status' directamente en 'changelogs'
      this.loadingOverlay = true;

      // Verificar si se está intentando actualizar al mismo estado
      if (lastChangelogStatus.sequenceOrder === this.selectedOption.value) {
        console.log('Se está intentando actualizar al mismo estado');
        this.loadingOverlay = false;
        return;
      }

      // Intentar actualizar el estado
      try {
        const res = await RefundServices.updateChangelogStatus({
          refundId: refund.id, // ID del reembolso
          changelogStatus: this.selectedOption.value, // Nuevo estado seleccionado
          observations: this.inputObservations, // Observaciones del usuario
          approvedAmount: parseInt(this.refundAmount.replaceAll('.', '')) || 0 // Monto aprobado, con validación
        });

        // Actualización exitosa
        console.log('db changelog statuses: ', res);
        this.updateChangelogOk = true;
        this.updateChangelogOkMsg = `Actualizado de "${lastChangelogStatus.name}" a "${this.selectedOption.text}"`;

        // Limpiar campos y estado de la interfaz
        this.loadingOverlay = false;
        this.dialog = false;
        this.inputObservations = '';
        this.refreshParent();
      } catch (error) {
        console.error('Error al intentar actualizar el estado:', error);
        this.updateChangelogOk = false;
        this.updateChangelogOkMsg = 'Ocurrió un error al intentar actualizar el estado';
        this.loadingOverlay = false;
      }
    },
    parseAmount(v) {
      let f1 = v.replaceAll('.', '');
      let f = f1.split('').reverse();
      let value = [];
      let tmp = 1;
      let aux = 0;
      while (aux < f.length) {
        value.splice(0, 0, f[aux]);
        if (tmp % 3 == 0 && aux < f1.length - 1) {
          value.splice(0, 0, '.');
          tmp = 1;
        } else tmp++;
        aux++;
      }
      this.refundAmount = value.join('');
    },
    async fetchRefundDetails() {
      this.loadingOverlay = true;
      try {
        const data = await RefundServices.getRefundDetails(this.data.id);
        this.refundData = data;
        this.user = data?.user || null;
        this.planData = data?.plan || null;
        this.petData = data?.pet || null;
        this.userBankDetails = data?.userBankDetails || null;
        this.files = data?.files || null;
        this.changelogs = data?.changelogs || [];
        this.service = data?.service || null;
        this.userComments = data?.refund.additionalComment;
        this.updateButtonText();
      } catch (error) {
        console.error('Error fetching refund details:', error);
      } finally {
        this.loadingOverlay = false;
      }
    },
    async updateRefundStatus() {
      try {
        await RefundServices.updateChangelogStatus({
          refundId: this.refundData.refund.id,
          changelogStatus: this.selectedOption.value,
          observations: this.inputObservations,
          approvedAmount: parseInt(CurrencyUtils.cleanCurrencyFormat(this.refundAmount)) || 0
        });
      } catch (err) {
        console.log(err);
      }
    },
    async saveRecords() {
      this.loadingOverlay = true;
      try {
        const approvedRegisterRefund = this.parseServiceRecordData();
        const digitalRecord = this.parseDigitalRecord();
        await this.updateRefundStatus();
        await RefundServices.postRecordAndRefundDetails({ approvedRegisterRefund, digitalRecord });
        this.updateChangelogOk = true;
        this.updateChangelogOkMsg = `Estado de reembolso actualizado"`;
        this.loadingOverlay = false;
        this.dialog = false;
        this.inputObservations = '';
        this.refreshParent();
      } catch (err) {
        console.log(err);
        this.updateChangelogOk = false;
        this.updateChangelogOkMsg = 'Ocurrió un error al intentar actualizar el estado';
      } finally {
        this.loadingOverlay = false;
      }
    },
    updateRadioOptions() {
      if (!this.service || !this.service.name) {
        this.showRadioButtons = false;
        this.radioOptions = [];
        return;
      }

      const serviceName = this.service.name.toLowerCase();

      if (
        serviceName.includes('vacunas obligatorias') ||
        serviceName.includes('vacunas opcionales')
      ) {
        this.showRadioButtons = true;
        if (this.petData.species === 'dog') {
          this.radioOptions = [
            { label: 'Antirrábica', value: 'antirrabica' },
            { label: 'Séxtuple/Óctuple', value: 'sextuple_octuple' },
            { label: 'KC', value: 'kc' }
          ];
        } else {
          this.radioOptions = [
            { label: 'Antirrábica', value: 'antirrabica' },
            { label: 'Triple felina', value: 'triple_felina' },
            { label: 'FeLV', value: 'felv' }
          ];
        }
      } else if (serviceName.includes('desparasitación')) {
        this.showRadioButtons = true;
        this.radioOptions = [
          { label: 'Externa', value: 'externa' },
          { label: 'Interna', value: 'interna' }
        ];
      } else {
        this.showRadioButtons = false;
        this.radioOptions = [];
      }
    },
    updateButtonText() {
      if (!this.service || !this.service.name) {
        this.buttonText = '';
        return;
      }

      const serviceName = this.service.name.toLowerCase();

      if (serviceName.includes('vacunas')) {
        this.buttonText = 'Agregar vacuna';
      } else if (serviceName.includes('desparasitación')) {
        this.buttonText = 'Agregar desparasitación';
      } else {
        this.buttonText = '';
      }
    },
    onAddButtonClick() {
      // Lógica para agregar vacuna o desparasitación
      if (this.buttonText === 'Agregar vacuna') {
        // logica agregar vacuna
        console.log('Agregar vacuna');
        // abrir un diálogo o redirigir a otra vista
      } else if (this.buttonText === 'Agregar desparasitación') {
        //agregar desparasitación
        console.log('Agregar desparasitación');
        // diálogo o redirigir a otra vista
      }
    },
    addEntry() {
      this.entries.push({
        selectedOption: null,
        refundApprovedDate: '',
        menuRefundApprovedDate: false,
        postureFrequency: null,
        userNotes: ''
      });
    }
  },

  computed: {
    userExpenseFormatted: {
      get() {
        return this.formatNumber(this.userExpense);
      },
      set(newValue) {
        this.userExpense = newValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
      }
    },
    refundAmountFormatted: {
      get() {
        return this.formatNumber(this.refundAmount);
      },
      set(newValue) {
        this.refundAmount = newValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
      }
    },
    isExpanded() {
      return this.activePanel !== null; // Check if the panel is expanded
    },
    userInfoItems() {
      if (!this.user) return [];
      return [
        { label: 'Nombre', value: `${this.user.fullname}` },
        { label: 'RUT', value: this.user.dni },
        { label: 'Email', value: this.user.email },
        {
          label: 'Teléfono',
          value: this.user.phone
        },
        { label: 'País', value: this.user.country }
      ];
    },
    petInfoItems() {
      if (!this.petData) return [];
      const species = this.petData.species === 'dog' ? 'Perro' : 'Gato';
      return [
        { label: 'Nombre', value: this.petData.name },
        { label: 'Especie', value: species },
        { label: 'Raza', value: this.petData.breed },
        { label: 'Edad', value: this.petData.age },
        { label: 'Referencia', value: this.petData.ref },
        { label: 'Estado membresía', value: MiscUtils.parseMembershipStatus(this.petData.status) }
      ];
    },
    bankInfoItems() {
      if (!this.userBankDetails) return [];
      return [
        { label: 'Banco', value: this.userBankDetails.bank },
        { label: 'Tipo de cuenta', value: this.userBankDetails.accountType },
        { label: 'Nro de cuenta', value: this.userBankDetails.accountNumber }
      ];
    },
    serviceInfoItems() {
      if (!this.service) return [];
      console.log('Nombre del servicio:', this.service.name);

      return [
        { label: '($) Tope de servicio', value: this.service.serviceCap },
        { label: '($) Monto usado en este período', value: this.service.usedAmount },
        { label: '($) Monto disponible', value: this.service.availableAmount },
        { label: 'Eventos por año', value: this.service.maxEvents },
        { label: 'Eventos usados en este período', value: this.service.usedEvents }
      ];
    },
    attachmentsInfoItems() {
      if (!this.files) return [];
      return this.files.map(attachment => ({
        label: attachment.type,
        value: '',
        link: attachment.url
      }));
    },
    planInfoItems() {
      if (!this.planData) return [];
      return [
        {
          label: 'Nombre del Plan',
          value: this.planData.name || 'Sin nombre',
          link: `/plans/${this.planData.id}`
        },
        {
          label: 'Fecha de Inicio membresía',
          value: this.planData.startDate || 'Sin fecha de inicio'
        },
        {
          label: 'Termino de carencia',
          value: this.planData.endSupplierGraceDays
        },
        {
          label: 'Fecha de Fin Plan',
          value: this.planData.endDate || 'Sin fecha de fin'
        },
        {
          label: 'Modalidad de pago',
          value: this.planData.paymentType
        }
      ];
    }
  },
  watch: {
    service: {
      immediate: true,
      handler() {
        this.updateRadioOptions();
        this.updateButtonText();
      }
    },
    dialog(newVal) {
      if (newVal) {
        this.fetchRefundDetails();
      }
    }
  }
};
</script>

<style>
.theme--light.v-label {
  color: #abafb1; /* Change to your desired color   */
}

.custom-placeholder input::placeholder {
  color: #abafb1; /* Change placeholder color */
  opacity: 1; /* Ensure visibility */
  font-size: 14px; /* Change font size */
}

.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #eff2f5 !important;
  border-color: transparent;
  border-style: solid;
  border-width: thin 0 thin 0;
  transform: scaleX(0);
}
.v-text-field input {
  background-color: transparent !important; /* Fondo transparente para el input */
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: transparent;
}
.v-input input:focus {
  background-color: transparent;
  border-color: transparent;
}

.v-input__slot {
  margin: 0;
}

.v-menu__content {
  box-shadow: none;
}

.custom-select .v-select__slot {
  background-color: #eff2f5;
}

.custom-datepicker .v-input__control {
  background-color: #eff2f5 !important;
}

.v-expansion-panel-content {
  margin-bottom: 16px;
}

.custom-button {
  color: #6a7682 !important;
  font-family: 'Inter', sans-serif !important;
  box-shadow: none !important;
}
.custom-bg-input input {
  background-color: #eff2f5 !important;
}
</style>
