import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendURL = process.env.VUE_APP_BACKEND_URL_V2;
const backendURLV3 = process.env.VUE_APP_BACKEND_URL_V3;
export default class CompaniesService {
  static async getCompanies(page, limit, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `page=${page}&limit=${limit}`;
      if (search) {
        url = `page=${page}&limit=${limit}&search=${search}`;
      }
      const response = await axios.get(`${backendURL}/companies/all/get?${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createCompany(newCompany) {
    try {
      newCompany.businessName = newCompany.name;
      newCompany.userObj = parseInt(newCompany.userObj);
      newCompany.membershipLimit = parseInt(newCompany.membershipLimit);
      newCompany.membershipObj = parseInt(newCompany.membershipObj);
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${backendURL}/companies`;
      const response = await axios.post(url, newCompany, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async updateCompany(companyId, newCompany) {
    try {
      newCompany.businessName = newCompany.name;
      newCompany.userObj = parseInt(newCompany.userObj);
      newCompany.membershipLimit = parseInt(newCompany.membershipLimit);
      newCompany.membershipObj = parseInt(newCompany.membershipObj);
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${backendURL}/companies/${companyId}`;
      const response = await axios.put(url, newCompany, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async getCompanyPlans(page, limit, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${adminUrl}/company/plans?page=${page}&limit=${limit}&id=${companyId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        console.log(response.data.data);
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'err' };
    }
  }

  static async getAllCompanyPlans(page, limit, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${adminUrl}/company?page=${page}&limit=${limit}&companyId=${companyId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'err' };
    }
  }

  static async postCompanyPlan(companyId, planId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${backendURLV3}/companies-plans`;
      const response = await axios.put(
        url,
        { companyId, planId },
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async downloadUsersFile(companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const url = `${adminUrl}/company/users/export`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { id: companyId },
        responseType: 'blob'
      });

      if (response.status === 200) {
        // Crear un Blob a partir de la respuesta
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        // Obtener el nombre de archivo desde el encabezado 'Content-Disposition', si está presente
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'users.xlsx';
        if (contentDisposition) {
          let fileNameMatch = contentDisposition.match(/filename\*?=(['"])(.*?)\1/);
          if (fileNameMatch && fileNameMatch.length >= 3) {
            fileName = fileNameMatch[2];
          } else {
            // Intentar con otro patrón
            fileNameMatch = contentDisposition.match(/filename\*?=([^;]+)/);
            if (fileNameMatch && fileNameMatch.length >= 2) {
              fileName = fileNameMatch[1].trim();
            }
          }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(downloadUrl);

        return { status: 'ok', success: true };
      } else {
        throw new Error(`Error al descargar el archivo: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      throw error;
    }
  }
}
