<template>
  <div>
    <v-overlay :value="loading" :z-index="999999">
      <v-col class="align-center justify-center text-center">
        <v-progress-circular
          :size="120"
          :width="8"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <h3 class="my-3">Agregando usuario...</h3>
      </v-col>
    </v-overlay>
    <v-dialog
      persistent
      v-model="confirmB2CDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <v-card class="pa-6">
        <v-card-title class="text-uppercase">
          <div>Agregar usuario y membresia</div>
        </v-card-title>
        <hr class="my-3" />
        <v-card-text class="my-0 py-0">
          <p>Esta acción creará un usuario y membresia con estado <b>pagado.</b></p>
          <p>Recuerde verificar que el usuario ya realizó el pago.</p>
        </v-card-text>
        <hr class="my-3" />
        <v-card-actions class="justify-end">
          <v-btn color="primary" rounded @click="createB2CUser">
            Aceptar
          </v-btn>
          <v-btn text color="primary" rounded @click="closeConfirmB2CDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="confirmB2BDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <v-card class="pa-6">
        <v-card-title class="text-uppercase">
          <div>Agregar usuario</div>
        </v-card-title>
        <hr class="my-3" />
        <v-card-text class="my-0 py-0">
          <p>Esta acción creará un usuario B2B</p>
          <p>Recuerde verificar la existencia de este usuario en la empresa asociada</p>
        </v-card-text>
        <hr class="my-3" />
        <v-card-actions class="justify-end">
          <v-btn color="primary" rounded @click="createB2BUser">
            Aceptar
          </v-btn>
          <v-btn text color="primary" rounded @click="closeConfirmB2BDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarOk" color="green" :timeout="timeout">
      Usuario creado exitosamente

      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarOk = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarFailed" color="red" :timeout="timeout">
      Error al crear usuario

      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarFailed = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Crear Usuario</h1>
    </v-row>

    <v-form ref="newUserForm" v-model="formValid">
      <v-col>
        <!-- País -->
        <h3>País</h3>
        <v-row class="my-1">
          <v-col cols="4">
            <v-select
              label="País"
              v-model="selectedCountry"
              :items="countries"
              :loading="loadingCountries"
              @change="changeCountry"
              item-value="id"
              item-text="text"
              :rules="requiredRules"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <h3>Tipo de usuario</h3>
        <!-- Tipo de usuario -->
        <v-row class="my-1">
          <v-col cols="4">
            <v-select
              label="Tipo de usuario"
              v-model="newUser.type"
              :rules="requiredRules"
              :items="userTypes"
              required
              outlined
              dense
              hide-details="auto"
              @change="getData"
            ></v-select>
          </v-col>
        </v-row>

        <h3>Datos de usuario</h3>
        <!-- Nombre, apellido y RUT -->
        <v-row class="my-1">
          <v-col cols="4">
            <v-text-field
              label="Nombres"
              v-model="newUser.name"
              :rules="nameRules"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Apellidos"
              v-model="newUser.surname"
              :rules="nameRules"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :label="countryIdentifierPlaceholder"
              v-model.lazy="newRut"
              required
              outlined
              dense
              @input="changeRut"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Correo, teléfono y cumpleaños -->
        <v-row class="my-1">
          <v-col cols="4">
            <v-text-field
              label="Correo electrónico"
              v-model="newUser.email"
              :rules="emailRules"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Teléfono"
              v-model="newUser.phone"
              outlined
              dense
              @input="changePhone"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="showBirthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newUser.birthday"
                  label="Fecha de nacimiento"
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newUser.birthday"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="saveDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- Región y comuna -->
        <v-row class="my-1">
          <v-col cols="4">
            <v-select
              label="Región"
              :items="regions"
              item-text="name"
              v-model="newUser.address.region"
              :loading="!regions.length && selectedCountry.iso == 'CL'"
              :disabled="!regions.length && selectedCountry.iso != 'CL'"
              outlined
              dense
              @change="getCommunesByRegion($event)"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Comuna"
              :items="communes"
              item-text="name"
              v-model="newUser.address.commune"
              :disabled="!communes.length && selectedCountry.iso != 'CL'"
              :loading="loadingCommunes"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <!-- Dirección, número y extra -->
        <v-row class="my-1">
          <v-col cols="6">
            <v-text-field
              label="Dirección"
              v-model="newUser.address.street"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Número"
              v-model="newUser.address.number"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Extra"
              v-model="newUser.address.extra"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Beneficio (solo para B2B) -->
        <h3 v-if="newUser.type === 'B2B'">Beneficio</h3>
        <v-row class="my-1" v-if="newUser.type === 'B2B'">
          <v-col cols="4">
            <v-select
              label="Empresa"
              v-model="companySelected"
              :items="companies"
              item-text="name"
              item-value="id"
              :rules="requiredRules"
              :loading="!companies.length"
              @change="getCompanyPlan"
              return-object
              outlined
              dense
              required
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Plan"
              v-model="planSelected"
              :items="plans"
              item-text="name"
              item-value="id"
              :rules="requiredRules"
              :loading="loadingPlans"
              return-object
              outlined
              dense
              :required="companySelected"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Membresía (solo para B2C) -->
        <h3 v-if="newUser.type === 'B2C'">Membresía</h3>
        <v-row class="my-1" v-if="newUser.type === 'B2C'">
          <v-col cols="5">
            <v-text-field
              label="Nombre peludo"
              v-model="pet.name"
              :rules="nameRules"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Sexo"
              v-model="pet.sex"
              :items="[
                { text: 'Macho', value: 'male' },
                { text: 'Hembra', value: 'female' }
              ]"
              :rules="requiredRules"
              required
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="showPetBirthdateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pet.birthdate"
                  label="Fecha de nacimiento"
                  prepend-icon="mdi-calendar"
                  required
                  :rules="requiredRules"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pet.birthdate"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="2005-01-01"
                @change="saveDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="my-1" v-if="newUser.type === 'B2C'">
          <v-col cols="2">
            <v-select
              label="Especie"
              v-model="pet.species"
              :items="[
                { text: 'Gato', value: 'cat' },
                { text: 'Perro', value: 'dog' }
              ]"
              :rules="requiredRules"
              required
              outlined
              dense
              hide-details="auto"
              @change="getBreeds(pet.species)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              label="Raza"
              v-model="pet.breed"
              :items="breeds"
              :loading="!breeds.length && pet.species != ''"
              value="id"
              item-text="name"
              :rules="requiredRules"
              required
              outlined
              dense
              return-object
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="my-1" v-if="newUser.type === 'B2C'">
          <v-col cols="5">
            <v-select
              label="Plan"
              v-model="planSelected"
              :items="plans"
              item-text="name"
              item-value="id"
              return-object
              :rules="requiredRules"
              :loading="loadingPlans"
              @change="getPlans"
              required
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Modalidad de pago"
              v-model="paymentInterval"
              :items="[
                { text: 'Anual', value: 1 },
                { text: 'Mensual', value: 2 }
              ]"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
          <!--<v-col cols="4">
            <v-select
              label="Cupón"
              v-model="couponSelected"
              :items="coupons"
              :loading="!coupons.length"
              item-text="name"
              item-value="id"
              return-object
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>-->
        </v-row>
        <v-row class="my-1" v-if="newUser.type === 'B2C'">
          <v-col cols="5">
            <v-menu
              ref="menu"
              v-model="showStartDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Fecha inicio membresía"
                  prepend-icon="mdi-calendar"
                  required
                  :rules="requiredRules"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="2023-01-01"
                @change="saveDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-btn :disabled="!formValid" color="primary" rounded @click="validate">
            Crear
          </v-btn>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import UsersService from '../services/users.service';
import AddressService from '../services/address.service';
import RutUtils from '../utils/rut.utils';
import CompaniesService from '../services/companie.service';
import PlansService from '../services/plans.service';
import PetsService from '../services/pets.service';
import CouponsService from '../services/coupons.service';
import CreateUserMembreshipModal from '../components/CreateUserMembershipModal';
import Overlay from '../components/Overlay.vue';
import CountryService from '../services/countries.service';

export default {
  comments: {
    CreateUserMembreshipModal,
    Overlay
  },
  data() {
    return {
      showModal: false,
      showBirthdayMenu: false,
      showPetBirthdateMenu: false,
      showStartDateMenu: false,
      activePicker: null,
      formValid: false,
      selectedCountry: { id: '', iso: '', text: '' },
      newRut: '',
      newUser: {
        type: '',
        country: { text: '', value: '' },
        name: '',
        rut: '',
        surname: '',
        email: '',
        phoneCountryCode: '56',
        phone: '',
        birthday: '',
        address: {
          region: '',
          commune: '',
          address: '',
          number: '',
          extra: ''
        }
      },
      pet: {
        name: '',
        species: '',
        birthdate: '',
        sex: '',
        breedId: '',
        age: { quantity: 0, unit: '' }
      },
      countries: [],
      regions: [],
      communes: [],
      userTypes: ['B2B', 'B2C'],
      breeds: [],
      plans: [],
      coupons: [],
      companies: [],
      paymentInterval: null,
      speciesSelected: null,
      companySelected: null,
      originSelected: null,
      couponSelected: null,
      planSelected: null,
      startDate: null,
      requiredRules: [v => !!v || 'Este campo es requerido'],
      nameRules: [v => !!v || 'El nombre es requerido'],
      emailRules: [
        v => !!v || 'Un correo electrónico es requerido',
        v => /.+@.+/.test(v) || 'Debe ser un correo electrónico válido'
      ],
      /*rutRules: [
        v => !!v || 'El nombre es requerido',
        v => RutUtils.validateRut(v) || 'RUT no válido'
      ],*/
      loadingCountries: false,
      confirmB2CDialog: false,
      confirmB2BDialog: false,
      timeout: 2000,
      snackbarOk: false,
      snackbarFailed: false,
      loading: false,
      loadingPlans: false,
      loadingCoupons: false,
      loadingCommunes: false,
      countryIdentifierPlaceholder: 'RUT',
      origin: ''
    };
  },
  mounted() {
    this.getCountries();
    this.getRegions();
  },
  methods: {
    cleanData() {
      this.plans = [];
      this.coupons = [];
      this.paymentInterval = null;
      this.speciesSelected = null;
      this.companySelected = null;
      this.originSelected = null;
      this.couponSelected = null;
      this.planSelected = null;
      this.newUser = {
        type: '',
        country: { text: '', value: '' },
        name: '',
        rut: '',
        surname: '',
        email: '',
        phoneCountryCode: '56',
        phone: '',
        birthday: '',
        address: {
          region: '',
          commune: '',
          address: '',
          number: '',
          extra: ''
        }
      };
      this.pet = {
        name: '',
        species: '',
        birthdate: '',
        sex: '',
        breedId: null,
        age: { quantity: 0, unit: '' }
      };
    },
    changeCountry(value) {
      const country = this.countries.filter(c => c.id === value)[0];
      this.plans = [];
      this.coupons = [];
      this.paymentInterval = null;
      this.speciesSelected = null;
      this.companySelected = null;
      this.originSelected = null;
      this.couponSelected = null;
      this.planSelected = null;
      this.selectedCountry = country;
      if (country.iso != 'CL') {
        this.newUser.region = null;
        this.newUser.commune = null;
        this.regions = [];
        this.communes = [];
        this.countryIdentifierPlaceholder = 'DNI';
      } else {
        this.getRegions();
      }
    },
    async getData() {
      if (this.newUser.type === 'B2B') {
        await this.getCompanies();
        this.plans = [];
      } else {
        await this.getPlans();
        await this.getCoupons();
      }
    },
    handleCreate() {
      if (this.newUser.type == 'B2C') {
        this.confirmB2CDialog = true;
      } else {
        this.createB2BUser();
      }
    },
    async getCountries() {
      try {
        this.loadingCountries = true;
        const response = await CountryService.getAllCountries(true);
        if (response.status === 'ok') {
          this.countries = response.data.map(c => {
            return { text: `${c.name ?? ''} ${c.flagUrlImage ?? ''}`, iso: c.iso, id: c.id };
          });
        }
        this.loadingCountries = false;
      } catch (err) {
        this.loadingCountries = false;
      }
    },
    async getRegions() {
      AddressService.getAllRegions().then(res => {
        if (res.status === 'ok') {
          this.regions = res.data.map(r => ({
            code: r.codigo,
            name: r.nombre
          }));
        }
      });
    },
    async getCommunesByRegion(region) {
      this.loadingCommunes = true;
      await AddressService.getCommunesByRegion(region).then(res => {
        if (res.status === 'ok') {
          this.communes = res.data.map(d => ({
            name: d.nombre,
            code: d.codigo
          }));
        }
      });
      this.loadingCommunes = false;
    },
    saveDate(date) {
      this.$refs.menu.save(date);
      this.showBirthdayMenu = false;
      this.showPetBirthdateMenu = false;
      this.showStartDateMenu = false;
    },
    async validate() {
      if (this.$refs.newUserForm.validate()) {
        try {
          if (this.newUser.type == 'B2B') {
            this.triggerConfirmB2BDialog();
          } else {
            this.triggerConfirmB2CDialog();
          }
        } catch (err) {
          console.error('Error al crear el usuario:', err.message);
        }
      }
    },
    async createB2CUser() {
      this.closeConfirmB2CDialog();
      this.loading = true;
      this.pet.age = this.getAge(this.pet.birthdate);
      this.newUser.country = this.selectedCountry.iso;
      const data = this.generateNewUserMembershipObj();
      const response = await UsersService.createB2CUser(data);
      if (response.status === 'ok') {
        this.snackbarOk = true;
        //this.cleanData();
      } else {
        this.snackbarFailed = true;
      }
      this.loading = false;
    },
    async createB2BUser() {
      this.closeConfirmB2BDialog();
      this.loading = true;
      this.newUser.country = this.selectedCountry.iso;
      const response = await UsersService.createB2BUser(
        this.newUser,
        this.planSelected.id,
        this.companySelected.id
      );
      if (response.status === 'ok') {
        this.snackbarOk = true;
        this.$refs.newUserForm.resetValidation();
      } else {
        this.snackbarFailed = true;
      }
      this.loading = false;
    },
    async getCompanies() {
      this.loadingData = true;
      const res = await CompaniesService.getCompanies(1, 100);
      if (res.status === 'ok') {
        this.companies = [...this.companies, ...res.data.companies];
      }
      this.loadingData = false;
    },
    async getPlans() {
      this.loadingPlans = true;
      const res = await PlansService.getB2CPlanToAssociate(this.selectedCountry.id);
      if (res.status === 'ok') {
        this.plans = res.data;
      }
      this.loadingPlans = false;
    },
    async getCompanyPlan() {
      this.loadingPlans = true;
      const res = await PlansService.getPlanByCompanyId(this.companySelected.id);
      if (res.status === 'ok') {
        this.plans = res.data;
      }
      this.loadingPlans = false;
    },
    async getBreeds(species) {
      this.loadingBreeds = true;
      try {
        const breeds = await PetsService.getBreeds(species);
        this.breeds = breeds.data;
        this.breeds.splice(0, 0, { name: 'Mestizo', id: null });
      } catch (err) {
        console.error('Error al obtener las razas:', err.message);
      } finally {
        this.loadingBreeds = false;
      }
    },
    async getCoupons() {
      this.loadingData = true;
      const res = await CouponsService.getActiveCoupons(this.selectedCountry.iso); // Iso
      if (res.status === 'ok') {
        this.coupons = res.data;
      }
      this.loadingData = false;
    },
    changeRut(rut) {
      this.newUser.rut = RutUtils.clean(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    changePhone(phone) {
      this.newUser.phone = phone;
    },
    getAge(birthdate) {
      const now = new Date();
      const birth = new Date(birthdate);

      let years = now.getFullYear() - birth.getFullYear();
      let months = now.getMonth() - birth.getMonth();

      if (months < 0) {
        years--;
        months += 12;
      }

      if (years > 0) {
        return { quantity: years, unit: 'Y' };
      } else {
        return { quantity: months, unit: 'M' };
      }
    },
    triggerConfirmB2CDialog() {
      this.confirmB2CDialog = true;
    },
    closeConfirmB2CDialog() {
      this.confirmB2CDialog = false;
    },
    triggerConfirmB2BDialog() {
      this.confirmB2BDialog = true;
    },
    closeConfirmB2BDialog() {
      this.confirmB2BDialog = false;
    },
    getCountryCodePhone(iso) {
      //assumes for now only chile and perú
      return iso == 'CL' ? '56' : '51';
    },
    generateNewUserMembershipObj() {
      this.newUser.phoneCountryCode = this.getCountryCodePhone(this.selectedCountry.iso);
      this.newUser.address = {
        street: this.newUser.address.street,
        number: this.newUser.address.number,
        extra: this.newUser.address.extra,
        commune: {
          code: '',
          name: this.newUser.address.commune,
          region: {
            code: '',
            name: this.newUser.address.region
          }
        },
        placeId: '',
        region: {
          code: '',
          name: this.newUser.address.region
        }
      };
      this.newUser.type = this.newUser.type.toLowerCase();
      this.pet.breedId = this.pet.breed?.id ?? null;
      delete this.pet.breed;
      return {
        user: this.newUser,
        pet: this.pet,
        planId: this.planSelected.id,
        startDate: this.startDate,
        paymentInterval: this.paymentInterval
      };
    }
  }
};
</script>
